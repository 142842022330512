import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'

import { Layout, Scramble } from '../components'

const Wrapper = styled.div`
  max-width: 640px;
  margin: 0px auto;
  padding-bottom: 2em;
`

const Date = styled.h3`
  display: block;
  color: #aaa;
  margin-top: -1rem;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
`

const Indent = styled.div`
  margin-left: 0.5rem;
`

export default ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <h1><Scramble>{post.frontmatter.title}</Scramble></h1>
      <Indent>
        <Date>{post.frontmatter.date}</Date>
      </Indent>
      <hr />
      <div dangerouslySetInnerHTML={{ __html: post.html }} />
    </Layout>
  )
}

export const query = graphql`
  query BlogPostQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date(formatString: "MMM DD, YYYY")
      }
      html
    }
  }
`
